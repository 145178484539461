import { forwardRef, useState, useEffect } from 'react';
const ListaChains = forwardRef((props, ref) => {
    
  const { top, left,position, onChainSelect, token, tokenRecebe  } = props;
    
  const shouldDisplayChain = (chain) => {
    if (chain === "Tron" && token === "BRL" && tokenRecebe === "USDT") {
      return true
    } 

    else if (chain === "XRP" && token === "BRL" && tokenRecebe === "BRLA") {
return true
    } 

    else if (chain === "Tron" && token === "USDT" && tokenRecebe === "USDT") {
      return true
          } 

          else if (chain === "Moonbeam" && token === "BRL" && tokenRecebe === "BRLA") {
            return true
                } 

                else if (chain === "Celo" && token === "BRL" && tokenRecebe === "BRLA") {
                  return true
                      } 
      
      

    else if (chain === "Polygon") {
      return true
    }
  };  

    return(
        <div
        ref={ref}
        data-popper-reference-hidden="false"
        data-popper-escaped="false"
        data-popper-placement="bottom"
        className="np-panel np-panel--open"
        style={{
         
          inset: "0px auto auto 0px",
          zIndex:300000,
          marginTop:top,
          marginLeft:left,
          position:position
         
        }}
      >
        <div className="np-panel__content">
          <ul
            id="tw-calculator-source-select-listbox"
            role="listbox"
            tabIndex={-1}
            className="np-dropdown-menu np-dropdown-menu-desktop np-dropdown-menu-lg p-a-1"
          >
            
            <li className="np-dropdown-header np-text-title-group">
              Todas as redes
            </li>
          
            {shouldDisplayChain('Polygon') && (
            <li
       onClick={(e) => {
        e.stopPropagation();
        props.onChainSelect({
          flag: 'currency-flag-polygon',
          abbreviation: 'Polygon'
        });
      }}
              id="option-tw-calculator-source-select-BRL"
              aria-selected="true"
              role="option"
              tabIndex={-1}
              className={`np-dropdown-item clickable ${
                props?.selectedTokenRecebe?.abbreviation === 'Polygon' ? 'active' : ''
              }`}
            >
              <a>
                <div className="d-flex np-option-content">
                  <div className="d-flex flex-column">
                    <i className="currency-flag currency-flag-polygon" />
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <span className="np-text-body-large">
                      MATIC
                      <span className="np-text-body-default m-l-1">
                        Polygon
                      </span>
                    </span>
                  </div>
                </div>
              </a>
            </li>
)}


{shouldDisplayChain('Tron') && (       
            <li
           
              id="option-tw-calculator-source-select-USD"
              aria-selected="false"
              role="option"
              tabIndex={-1}
              className="np-dropdown-item clickable"
              onClick={(e) => {
                e.stopPropagation();
                props.onChainSelect({
                  flag: 'currency-flag-tron',
                  abbreviation: 'Tron'
                });
              }}
            >
              <a>
                <div className="d-flex np-option-content">
                  <div className="d-flex flex-column">
                  <img style={{width:"35px", height:'35px'}} src='https://brla.digital/static/trx.svg'/>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <span className="np-text-body-large">
                      TRX
                      <span className="np-text-body-default m-l-1">
                        Tron
                      </span>
                    </span>
                  </div>
                </div>
              </a>
            </li>

            )}
            {shouldDisplayChain('XRP') && (

            <li
           
           id="option-tw-calculator-source-select-USD"
           aria-selected="false"
           role="option"
           tabIndex={-1}
           className="np-dropdown-item clickable"
           onClick={(e) => {
             e.stopPropagation();
             props.onChainSelect({
               flag: 'currency-flag-xrp',
               abbreviation: 'XRP'
             });
           }}
         >
           <a>
             <div className="d-flex np-option-content">
               <div className="d-flex flex-column">
               <img style={{width:"35px", height:'35px'}} src='/xrp.svg'/>
               </div>
               <div className="d-flex flex-column justify-content-center">
                 <span className="np-text-body-large">
                   XRP
                   <span className="np-text-body-default m-l-1">
                     Ripple
                   </span>
                 </span>
               </div>
             </div>
           </a>
         </li>
          )}


{shouldDisplayChain('Moonbeam') && (

<li

id="option-tw-calculator-source-select-USD"
aria-selected="false"
role="option"
tabIndex={-1}
className="np-dropdown-item clickable"
onClick={(e) => {
 e.stopPropagation();
 props.onChainSelect({
   flag: 'currency-flag-moonbeam',
   abbreviation: 'GLMR'
 });
}}
>
<a>
 <div className="d-flex np-option-content">
   <div className="d-flex flex-column">
   <img style={{width:"25px", height:'25px', marginRight:'10px'}} src='/moonbeam.png'/>
   </div>
   <div className="d-flex flex-column justify-content-center">
     <span className="np-text-body-large">
       GLMR
       <span className="np-text-body-default m-l-1">
         Moonbeam
       </span>
     </span>
   </div>
 </div>
</a>
</li>
)}

{shouldDisplayChain('Celo') && (

<li

id="option-tw-calculator-source-select-USD"
aria-selected="false"
role="option"
tabIndex={-1}
className="np-dropdown-item clickable"
onClick={(e) => {
 e.stopPropagation();
 props.onChainSelect({
   flag: 'currency-flag-celo',
   abbreviation: 'CELO'
 });
}}
>
<a>
 <div className="d-flex np-option-content">
   <div className="d-flex flex-column">
   <img style={{width:"25px", height:'25px', marginRight:'10px'}} src='/celo.png'/>
   </div>
   <div className="d-flex flex-column justify-content-center">
     <span className="np-text-body-large">
       CELO
       <span className="np-text-body-default m-l-1">
         Celo
       </span>
     </span>
   </div>
 </div>
</a>
</li>
)}

          
          </ul>
        </div>
      </div>
    )
});
export default ListaChains;